import React from "react";

const QuantityPicker = ({ quantity, setQuantity }) => {
  const handleQuantity = (func) => {
    if (func === "add") {
      setQuantity(quantity + 1);
    }
    if (func === "minus") {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    }
  };

  return (
    <>
      <input type="hidden" name="quantity" value={quantity} />
      <div className="d-flex align-items-center me-1 me-md-6 mb-3 mb-sm-0 px-5 border border-dark rounded-3">
        {/* Minus button */}
        <span
          className="py-2 px-3"
          role="button"
          tabIndex="0"
          aria-label="Reduce quantity by one"
          onClick={() => {
            handleQuantity("minus");
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleQuantity("minus");
          }}
        >
          -
        </span>

        {/* Selected value text */}
        <span
          className="user-select-none"
          aria-label={`Quantity ${quantity}`}
          aria-live="polite"
        >
          {quantity}
        </span>

        {/* Add button */}
        <span
          className="py-2 px-3"
          role="button"
          tabIndex="0"
          aria-label="Increase quantity by one"
          onClick={() => {
            handleQuantity("add");
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleQuantity("add");
          }}
        >
          +
        </span>
      </div>
    </>
  );
};

export default QuantityPicker;
