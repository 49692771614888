import React from "react";
import { Helmet } from "react-helmet";

const SchemaRecipe = ({ recipe }) => {
  const {
    title,
    description,
    media,
    preparation_time: prepTime,
    cook_time: cookTime,
    servings,
    chef,
    categories,
    ingredients,
    steps,
  } = recipe.data;

  // Create image object array
  const images = media.map(
    (item) =>
      `{
      "@type": "ImageObject",
      "url": "${item.image.url}",
      ${item.image.alt ? `"description":"${item.image.alt}",` : ``}
      "width": "${item.image.dimensions.width}",
      "height": "${item.image.dimensions.height}"
    }`
  );

  // Author
  const author = chef?.document?.data?.name
    ? `"author": {
        "@type": "Person",
        "name": "${chef.document.data.name}"
      },`
    : "";

  // Calculate recipe times
  const prep = prepTime.replace("'", "M").replace('"', "S");
  const cook = cookTime.replace("'", "M").replace('"', "S");
  const totalM = Number(prep.split("M")[0]) + Number(cook.split("M")[0]);
  const totalS =
    Number(prep.split("M")[1].split("S")[0]) +
    Number(cook.split("M")[1].split("S")[0]);

  // Category
  const recipeCategory =
    categories.length > 0
      ? `"recipeCategory":"${categories[0].category?.document?.data?.title}",`
      : "";

  // Ingredients
  const recipeIngredient = ingredients.map((ing) => `"${ing.item}"`);

  // Steps
  const recipeInstructions = steps.map(
    (step, index) => `{
      "@type": "HowToStep",
      "name": "Βήμα ${index + 1}",
      "text": "${step.step_instructions.text}",
      "image": "${step.step_image.url}"
    }`
  );

  return (
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org/",
          "@type": "Recipe",
          "name": "${title}",
          "description": "${description.text}",
          ${images.length > 0 ? `"image": [${images.join(",")}],` : ""}
          ${author}
          "prepTime": "PT${prep}",
          "cookTime": "PT${cook}",
          "totalTime": "PT${totalM}M${totalS > 0 ? `${totalS}S` : ""}",
          "recipeYield": "${servings}",
          ${recipeCategory}
          "recipeIngredient": [${recipeIngredient.join(",")}],
          "recipeInstructions": [${recipeInstructions.join(",")}]
        }
      `}</script>
    </Helmet>
  );
};

export default SchemaRecipe;
