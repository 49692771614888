import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
// eslint-disable-next-line import/no-unresolved
import { Navigation, A11y } from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
import { RichText } from "prismic-reactjs";
import { useCart } from "react-use-cart";

import { Col, Container, Row, Button, Alert } from "react-bootstrap";

import Layout from "../components/common/Layout";
import QuantityPicker from "../components/elements/QuantityPicker";
import RecipeStats from "../components/elements/RecipeStats";
import ProductSlider from "../components/elements/ProductSlider";
import ReviewsBanner from "../components/common/ReviewsBanner";
import Anchor from "../components/elements/Anchor";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import { ArrowDownBig, ArrowLeft, ArrowRight } from "../helpers/Icons";
import { useShop } from "../context/ShopContext";

import { Currency, nextMonday } from "../helpers";

import "../styles/pages/product.scss";
import SchemaRecipe from "../components/meta/SchemaRecipe";

const Product = ({ data }) => {
  if (!data) return null;

  const [quantity, setQuantity] = useState(1);
  const { addItem, totalItems, metadata } = useCart();
  const { signUp } = useShop();
  const box = metadata?.box || null;
  const customer = !!metadata?.customer;

  const product = data.prismicProduct;

  const {
    title,
    description,
    price,
    media,
    ingredients,
    steps,
    chef,
    categories,
  } = product.data;

  const {
    name: chefName,
    excerpt: chefExcerpt,
    image: chefImage,
  } = chef.document.data;

  const relatedProducts = data.allPrismicProduct?.edges;

  const categoryContent = categories?.length > 0 && (
    <div className="fs-5 mb-3 px-lg-7 text-end">
      <Anchor
        href={categories[0].category.document.url}
        key={categories[0].category.document.id}
        className="link-underline active"
      >
        {categories[0].category.document.data.title} collection
      </Anchor>
    </div>
  );

  const productData = {
    id: product.uid,
    url: product.url,
    title,
    price,
    media,
  };

  return (
    <Layout>
      <SchemaRecipe recipe={product} />
      <article className="product">
        <Container>
          <header className="pt-6 pb-6 text-center">
            <h1 className="h4">{title}</h1>
            <ArrowDownBig size={58} />
          </header>
          <section className="product__content mb-5">
            {/* ----- Product category header ----- */}
            {categoryContent}

            {/* ----- Product media content ----- */}
            <Row className="align-items-center">
              {media.length > 1 ? (
                <>
                  <Col
                    xs={{ span: 6, order: 2 }}
                    lg={{ span: 2, order: 0 }}
                    className="text-end"
                  >
                    <Button variant="link" className="swiper-prev-custom">
                      <ArrowLeft />
                    </Button>
                  </Col>
                  <Col lg={{ span: 8, order: 1 }}>
                    <Swiper
                      modules={[Navigation, A11y]}
                      navigation={{
                        prevEl: ".swiper-prev-custom",
                        nextEl: ".swiper-next-custom",
                      }}
                      slidesPerView={1}
                    >
                      {media.map((item) => (
                        <SwiperSlide key={item.image.url}>
                          <GatsbyImage
                            image={item.image.gatsbyImageData}
                            alt={item.image.alt || title}
                            className="bg-light"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Col>
                  <Col xs={{ span: 6, order: 2 }} lg={{ span: 2, order: 3 }}>
                    <Button variant="link" className="swiper-next-custom">
                      <ArrowRight />
                    </Button>
                  </Col>
                </>
              ) : (
                <Col lg={{ span: 8, offset: 2 }}>
                  <GatsbyImage
                    image={media[0].image.gatsbyImageData}
                    alt={media[0].image.alt || title}
                    className="bg-light"
                  />
                </Col>
              )}
            </Row>

            {/* ----- Product details ----- */}
            <Row>
              <Col lg={{ span: 8, offset: 2 }}>
                <div className="bg-white shadow">
                  <div className="pt-5 pb-4 px-4">
                    <RichText
                      render={description.richText}
                      serializeHyperlink={AnchorPrismic}
                    />
                  </div>
                  {customer ? (
                    <>
                      <p className="h6 mb-5 py-3 px-4 text-center bg-light">
                        <Currency value={price} /> / 2 Άτομα
                      </p>
                      <form className="d-flex align-items-center justify-content-center flex-wrap px-4">
                        <QuantityPicker
                          quantity={quantity}
                          setQuantity={setQuantity}
                        />
                        <Button
                          onClick={() => addItem(productData, quantity)}
                          disabled={!box || box.productLimit <= totalItems}
                        >
                          ΠΡΟΣΘΗΚΗ ΣΤΟ ΚΑΛΑΘΙ
                        </Button>
                      </form>
                      {!box && (
                        <Alert
                          variant="warning"
                          className="mw-600 mt-3 mx-auto text-center"
                        >
                          Επιλέξτε μία από τις συσκευασίες για να προσθέσετε την
                          συνταγή. <br />
                          <Alert.Link as={Anchor} href={signUp}>
                            Συνεχίστε την εγγραφή σας
                          </Alert.Link>
                        </Alert>
                      )}
                    </>
                  ) : (
                    <div className="px-4 text-center">
                      <Button
                        as={Anchor}
                        href={signUp}
                        className="px-5 fw-bold"
                      >
                        ΕΓΓΡΑΦΗ
                      </Button>
                    </div>
                  )}
                  <div className="d-flex justify-content-center flex-wrap py-5 px-4">
                    <RecipeStats data={product.data} />
                  </div>
                </div>
              </Col>
            </Row>
          </section>

          {/* ----- Product recipe ingredients ----- */}
          <section className="product__ingredients mb-5">
            <div className="pt-5 pb-6 text-center">
              <h2 className="h5 text-primary">
                Τι θα βρεις στο Hello-Chef κουτί (για 2 άτομα);
              </h2>
              <ArrowDownBig size={58} />
            </div>
            <ul className="product__ingredients-list mw-600 m-auto list-unstyled">
              {ingredients.map((ingredient) => (
                <li key={ingredient.item}>{ingredient.item}</li>
              ))}
            </ul>
          </section>

          {/* ----- Product recipe steps ----- */}
          <section className="product__recipe mb-5">
            <div className="pt-5 pb-6 text-center">
              <h2 className="h5 text-primary">Οδηγίες προετοιμασίας</h2>
              <ArrowDownBig size={58} />
            </div>
            <Row>
              {steps.map((step, index) => (
                <Col
                  md={6}
                  className="position-relative mb-5 px-md-5"
                  key={step.step_instructions.text}
                >
                  <span className="recipe__step-badge">{index + 1}</span>
                  {step.step_image.gatsbyImageData ? (
                    <GatsbyImage
                      image={step.step_image.gatsbyImageData}
                      alt={step.step_image.alt || ""}
                      className="mb-3 bg-light"
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center mb-3 p-3 bg-light"
                      style={{ height: "30vh", maxHeight: "330px" }}
                      aria-hidden="true"
                    />
                  )}
                  <RichText
                    render={step.step_instructions.richText}
                    serializeHyperlink={AnchorPrismic}
                  />
                </Col>
              ))}
            </Row>
          </section>
        </Container>

        {/* ----- Product recipe chef ----- */}
        <aside className="product__chef position-relative mb-5 pb-6 overflow-hidden">
          <div className="py-5 bg-light">
            <StaticImage
              src="../images/veggiespattern.png"
              alt=""
              className="chef-background-pattern"
              objectFit="contain"
              objectPosition="center bottom"
              layout="fullWidth"
            />
            <Container>
              <div className="pb-3 text-center">
                <h2 className="h5 text-primary">Ο Chef</h2>
                <ArrowDownBig size={58} />
              </div>
              <Row>
                <Col md={4}>
                  <figure className="w-75 mb-2 p-2 bg-white shadow">
                    <GatsbyImage
                      image={chefImage.gatsbyImageData}
                      alt={chefImage.alt || ""}
                    />
                  </figure>
                  <p className="h6 mt-3">
                    Chef
                    <br />
                    {chefName}
                  </p>
                </Col>
                <Col md={8}>
                  <p className="fs-5">{chefExcerpt}</p>
                </Col>
              </Row>
            </Container>
          </div>
        </aside>

        {/* ----- Related products ----- */}
        {relatedProducts && (
          <aside className="product__related mb-5">
            <Container>
              <div className="pb-4 text-center">
                <h2 className="h4">
                  Περισσότερα στο μενού της εβδομάδας, {nextMonday()}
                </h2>
                <ArrowDownBig size={58} />
              </div>
              <ProductSlider data={relatedProducts} />
            </Container>
          </aside>
        )}
      </article>
      <ReviewsBanner />
    </Layout>
  );
};

export const query = graphql`
  query ProductQuery($id: String) {
    prismicProduct(id: { eq: $id }) {
      ...prismicProductFragment
    }
    allPrismicProduct(
      filter: { type: { eq: "product" }, id: { ne: $id } }
      limit: 4
    ) {
      edges {
        node {
          ...prismicCardProductFragment
        }
      }
    }
  }
`;

export default Product;
